/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // Set up Google Analytics events
                if (typeof ga === 'function') {
                    Sage.common.gaTrackOutboundLinks();
					Sage.common.gaTrackDocumentLinks();
                }


                // requires .videoWrapper style
                // change static sized iframe video to responsive sized ( add checks to apply for any other than Youtube)
                if ($("iframe[src^='http://www.youtube.com'], iframe[src^='https://www.youtube.com']").length) {
                    $("iframe[src^='http://www.youtube.com'], iframe[src^='https://www.youtube.com']").removeAttr('height').removeAttr('width').wrap("<div class='videoWrapper'></div>");
                }


                // RESPONSIVE BREAKPOINT READER
                Sage.breakpoint.refreshValue();
                // UNCOMMENT THIS SECTION IF YOU WANT TO READ BREAKPOINT ON RESIZE
                // REQUIRES CLASSES LOCATED IN: _common.scss
                // $(window).resize(function () {
                //     Sage.breakpoint.refreshValue();
                //     //TEST VIEW BREAKPOINT
                //     //console.log( Sage.breakpoint.value);
                // }).resize();


                // RESPONSIVE TABLE FIXER
                // UNCOMMENT THIS SECTION IF YOU WANT RESPONSIVE TABLES
                // Sage.responsiveTable();

                //SHRINKING STICKY HEADER & SCROLL TRACKING
                Sage.didScroll = false;
                Sage.SeenReferences = false;
                Sage.SeenRegisterButton = false;

                window.addEventListener('scroll', function (event) {
                    if (!Sage.didScroll) {
                        Sage.didScroll = true;
                        // Header change
                        if (Sage.breakpoint.value >= 2) {
                            setTimeout(Sage.common.scrollPage(), 250);
                        }
                        // Tracking position
                        if(!$('body').hasClass('register')) {
                            if (!Sage.SeenReferences) {
                                setTimeout(Sage.common.trackOnScreen('#references', 'References'), 250);
                            }
                            if (!Sage.SeenRegisterButton) {
                                setTimeout(Sage.common.trackOnScreen('#register', 'Register Button'), 250);
                            }
                        }
                    }
                }, false);

				//
				// View article links
				//
				$(document).on('click', '.article-view-link', function() {
					ga('send', 'event', 'Article', 'View', $(this).data('headline'));
				});

				//
				// Social media menu link clicks
				//
				$('.share').click(function() {
					var popupWidth = 500, popupHeight = 500;
					var
						px = Math.floor(((screen.availWidth || 1024) - popupWidth) / 2),
						py = Math.floor(((screen.availHeight || 700) - popupHeight) / 2);
					var popup = window.open($(this).attr('href'), "social", "width="+popupHeight+",height="+popupWidth+",left="+px+",top="+py+",location=0,menubar=0,toolbar=0,status=0,scrollbars=1,resizable=1");
					popup.focus();

					ga('send', 'event', 'Share Intent', 'Click', $(this).data('channel'));

					return false;
				});

				//
				// Email link in soical media menu GA event
				//
				$('.social-email').click(function() {
					ga('send', 'event', 'Mailto Intent', 'Click', $(this).data('email'));
				});


				$('#social-media-menu-close-icon').click(function() {
					$('#social-media-menu-container').hide();
					$('#social-media-menu-opener').show();
					return false;
				});

				$('#social-media-menu-opener-icon').click(function() {
					$('#social-media-menu-container').show();
					$('#social-media-menu-opener').hide();
					return false;
				});

				$(window).resize(function() {
					$('#social-media-menu-container').show();
					$('#social-media-menu-opener').hide();
				});

				$('.navbar-toggle').click(function() {
					if ($('.navbar-toggle').hasClass('navbar-toggled')) {
						$('.navbar-toggle').removeClass('navbar-toggled');
					} else {
						$('.navbar-toggle').addClass('navbar-toggled');
					}
				});

            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            },
            gaLogEvent: function (event) {
                // General event logging function, intended to be used with .on('click') event handlers
                if (typeof event.data.label !== 'undefined') {
                    if (typeof event.data.value !== 'undefined') {
                        ga('send', 'event', event.data.category, event.data.action, event.data.label, event.data.value);
                    } else {
                        ga('send', 'event', event.data.category, event.data.action, event.data.label);
                    }
                } else {
                    ga('send', 'event', event.data.category, event.data.action);
                }
            },
            gaTrackOutboundLinks: function () {
                // Listen for links that don't match the current base URL and fire an event when clicked
                var currentUrl = window.location.href.substr(0, window.location.href.indexOf('/', 10));
                $('a[href^="http"]').not('[href^="' + currentUrl + '"]').each(function () {
                    $(this).on('click', {
                        category: 'Outbound Links',
                        action: 'Click',
                        label: this.href
                    }, Sage.common.gaLogEvent);
                });
            },

            gaTrackDocumentLinks: function () {

                // Set up tracking on clicks on documents, such as PDFs
                $('a:not([href^="mailto"])').filter(function () {

					var classname = $(this).attr('class');
					if (classname === 'share') {
						return false;
					}

                    // If this anchor doesn't have an HREF, ignore it.
                    if (typeof this.href !== 'string') {
                        return false;
                    }

                    // Try to split the HREF into pathinfo sements.
                    var pathinfo = this.href.split('/');
                    if (pathinfo.length === 0) {
                        return false;
                    }

                    // If the last segment contains a period, then it is most likely a document. Set up tracking.
                    return (pathinfo[pathinfo.length - 1].indexOf('.') > 0);
                }).each(function () {
                    $(this).on('click', {
                        category: 'Downloads',
                        action: this.href.substr(this.href.lastIndexOf('.') + 1).toUpperCase(),
                        label: this.href.substr(this.href.lastIndexOf('/') + 1)
                    }, Sage.common.gaLogEvent);
                });
            },


            trackOnScreen: function (elem, name) {
                var winTop = $(window).scrollTop();
                var winBottom = winTop + $(window).height();
                /* JS error
				var elemTop = $(elem).offset().top;
                if ((elemTop <= winBottom) && (elemTop >= winTop)){
                    //console.log(name+' seen.');
                    //console.log(Sage);
                    Sage['Seen'+name.replace(/\s/g, "")] = true;
                    ga('send', 'event', 'Content Consumption', 'Scroll', 'Reached '+name);
                }*/
            },
            scrollPage: function () {
                var sy = window.pageYOffset || document.documentElement.scrollTop;
                $header = $('header .navbar-brand, header nav, #social-media-menu #social-media-menu-container');
                if (sy >= 200) {
                    $header.addClass('scrolled');
                } else {
                    $header.removeClass('scrolled');
                }
                Sage.didScroll = false;
            }
        },
        'home': {
            init: function () {

            }
        },
        'neoheart_2017': {
			init: function () {


                function hideRadio(){
                    if($('#seasons_52').length){
                        $('.gchoice_10_5_1').remove();
                    }else{
                    }


                    if($('#roys_restaurant').length){
                        $('.gchoice_10_5_0').remove();
                    }else{
                    }
                }

                $(function() {
                    hideRadio();


                });




			}
		},
		'research_resources': {
			init: function() {
	
				//
				// Highlight update buttons
                //
				function highlight_update_buttons() {
					$('#hyperoxia-article-search-update').addClass('active-update-button');
					$('#hyperoxia-article-search-update2').addClass('active-update-button');
				}

				//
				// Unhightlight update buttons
				//
				function unhighlight_update_buttons() {
					$('#hyperoxia-article-search-update').removeClass('active-update-button');
					$('#hyperoxia-article-search-update2').removeClass('active-update-button');
				}	
	
				// Helper method(deprecated)
				function show_update_buttons() {
					if ($('#available-keywords-container').is(':visible')) {
						$('#hyperoxia-article-search-update').show();
						$('#hyperoxia-article-search-update2').show();
						$('#hyperoxia-article-search-hide').hide();
						$('#hyperoxia-article-search-hide2').hide();
					}
				}

				// Helper method(deprecated)
				function show_hide_buttons() {
					if ($('#available-keywords-container').is(':visible')) {
						$('#hyperoxia-article-search-update').hide();
						$('#hyperoxia-article-search-update2').hide();
						$('#hyperoxia-article-search-hide').show();
						$('#hyperoxia-article-search-hide2').show();
					}
				}

				// Helper method
				function show_update_hide_buttons() {

					/**
					 * New show/hide logic - inline and shown at all times
					 */
					if ($('#available-keywords-container').is(':visible')) {
						$('#hyperoxia-article-search-update').show();
						$('#hyperoxia-article-search-update2').show();
						$('#hyperoxia-article-search-hide').show();
						$('#hyperoxia-article-search-hide2').show();
					}
				}

				//
				// Hide available keywords list
				//
				function hide_available_keywords() {
					$('#hyperoxia-article-search-expand').show();
					// This hides the 2nd set of show/hide buttons within the container on bottom
					$('#available-keywords-container').hide();
					// show/hide buttons on top
					$('#hyperoxia-article-search-hide').hide();
					$('#hyperoxia-article-search-update').hide();
					return false;
				}

				//
				// Methods to show/hide available keywords for a category
				//
				function show_category_available_keywords(category) {
					category.parent().find('.available-keyword-rows').show();
					category.find('.fa-plus').hide();
					category.find('.fa-minus').show();
				}

				function hide_category_available_keywords(category) {
					category.parent().find('.available-keyword-rows').hide();
					category.find('.fa-plus').show();
					category.find('.fa-minus').hide();
				}

				function toggle_category_available_keywords(category) {
					if (category.find('.fa-plus').is(':visible')) {
						show_category_available_keywords(category);
					} else if (category.find('.fa-minus').is(':visible')) {
						hide_category_available_keywords(category);
					}
				}
		
				function is_mobile() {
					if ($('.is-mobile').is(':visible')) {
						return true;
					}
					return false;
				}

				var window_width = $(window).width();
				$(window).resize(function() {
					if ($(this).width() !== window_width) {
						if (is_mobile()) {
							$('.category-title').each(function() {
								hide_category_available_keywords($(this));
							});
						} else {
							$('.category-title').each(function() {
								show_category_available_keywords($(this));
							});
						}
					}
				});

				//
				// Define action for keyword category title in mobile view
				//      
				$('.category-title').click(function() {
					toggle_category_available_keywords($(this));
				});

				//
				// Show available keywords list
				//
				function show_available_keywords() {
					$('#hyperoxia-article-search-expand').hide();
					$('#available-keywords-container').show();

					show_update_hide_buttons();
					return false;
				}		

				//
				// Define action for the hide available keyword icon
				//
				$('#hyperoxia-article-search-hide')
					.add('#hyperoxia-article-search-hide2').click(function() {
						return hide_available_keywords();
				});

				//
				// Define action for the open available keyword icon
				//
				$('#hyperoxia-article-search-expand').click(function() {
					return show_available_keywords();
				});

				//
				// Uncheck all keywords in the available list
				//
				function uncheck_available_keywords() {
					$('.available-keyword').removeAttr('checked');
					show_update_hide_buttons();
				}

				//
				// Mark current search keywords as checked
				//
				function check_available_keywords(search_keywords) {

					//console.log(search_keywords.join());

					$('.available-keyword').removeAttr('checked');
					for (i = 0; i < search_keywords.length; i++) {
						var keyword = search_keywords[i];
						// Either full keyword matched
						$('.available-keyword[value="' + keyword + '"]').prop('checked', true);
						// Or abbr matched
						$('.available-keyword[data-abbr="' + keyword + '"]').prop('checked', true);
					}
					show_update_hide_buttons();
				}

				function show_spinner() {
					$('.loading').delay('fast').fadeIn();
				}

				function hide_spinner() {
					$('.loading').fadeOut();
				}

				//
				// Scroll to top of the search results
				//
				function to_search_results() {
					var offset = 150;
					if (is_mobile()) {
						offset = 0;
					}
					$('html, body').animate({
						'scrollTop': $('#search-results-anchor').offset().top - offset
					}, 700);
				}

				//
				// Scroll to top of the search keywords
				//
				function to_search_keywords() {
					var offset = 150;
					if (is_mobile()) {
						offset = 0;
					}
					$('html, body').animate({
						'scrollTop': $('#search-keywords-anchor').offset().top - offset
					}, 700);
				}

				//
				// AJAX function that gets and populates the search history
				//
				function get_search_history() {
					$.ajax({
						url: 'wp-admin/admin-ajax.php',
						type: 'GET',
						data: {
							action: 'hyperoxia_search_history',
						},
						success: function(response) {
							$('#hyperoxia-article-search-history-results').html(response);
						}
					});
				}

				//
				// AJAX function that calls the search
				//
				function search(search_keywords, page) {

					show_spinner();

					if (page === undefined) {
						page = 1;
					}

					check_available_keywords(search_keywords);

					$.ajax({
						url: 'wp-admin/admin-ajax.php',
						type: 'POST',
						data: {
							action: 'hyperoxia_article_search',
							search_keywords: search_keywords,
							page: page,
						},
						success: function(response) {
							setTimeout(function(){
								$('#hyperoxia-article-search-results').html(response);
								hide_spinner();
								to_search_results();
							}, 1000);
						}
					});
				}
					
				//
				// Clear the search results
				//
				function clear_search() {
					uncheck_available_keywords();
					hide_available_keywords();
					$('#hyperoxia-article-search-results').html('');
					to_search_keywords();
				}

				//
				// Un-highlight all predefined keywords box
				//
				function unhighlight_predefined_keywords() {
					$('.predefined-keywords').each(function() {
						$(this).removeClass('active-search-keywords');
					});
				}
			
				//
				// Highlight predefined keyword box
				function highlight_predefined_keyword(box) {
					box.addClass('active-search-keywords');
				}

				//
				// Update selected keyword count
				//
				function update_selected_keyword_count() {
					var checked_keywords = $('.available-keyword[type="checkbox"]:checked');
					var checked_keyword_count = checked_keywords.length;
					
					//$('#keyword-selected-count').html(checked_keyword_count);
				
					$('.available-keyword').each(function() {
						$(this).removeClass('inactive-keyword');
						$(this).parent().find('label').removeClass('inactive-keyword-label');
					});

					if (checked_keyword_count === 4) {
						$('.available-keyword:not(:checked)').each(function() {
							$(this).addClass('inactive-keyword');
							$(this).parent().find('label').addClass('inactive-keyword-label');
						});
					}
				}

				//
				// Define action for each predefined keyword group
				//
				$(document).on('click', '.predefined-keywords', function() {
					var search_keywords = [];
					
					$(this).find('.predefined-keyword').each(function(i, keyword) {
						search_keywords[i] = $.trim($(keyword).html());
					});

					unhighlight_predefined_keywords();
					highlight_predefined_keyword($(this));

					search(search_keywords);

					update_selected_keyword_count();
 
					ga('send', 'event', 'Search', 'Keyword Button', search_keywords.join());

				});
			
				//
				// Define action for each search keyword history group
				//	
				$(document).on('click', '.history-keywords', function() {
					var search_keywords = [];
					
					$(this).find('.history-keyword').each(function(i, keyword) {
						search_keywords[i] = $.trim($(keyword).html());
					});

					unhighlight_predefined_keywords();

					search(search_keywords);

					update_selected_keyword_count();

					ga('send', 'event', 'Search', 'See what others are reading', search_keywords.join());
				});
			
				//
				// Get search result keywords
				//
				function get_search_result_keywords() {
					
					var search_keywords = [];
					
					$('.search-result-keyword').each(function(i, keyword) {
						search_keywords[i] = $(keyword).find('.keyword-content').html();
					});	

					return search_keywords;
				}	

				//
				// Define action for pagination page
				//
				$(document).on('click', '.search-result-page', function() {
					var page = $(this).data('page');
					var search_keywords = get_search_result_keywords();
					search(search_keywords, page);
				});

				//
				// Define action for the available keyword
				//
				$('.available-keyword').click(function() {

					var checked_keywords = $('.available-keyword[type="checkbox"]:checked');
					var checked_keyword_count = checked_keywords.length;

					// Exceed max. keywords
					if (checked_keyword_count > 4) {
						return false;
					}
					
					unhighlight_predefined_keywords();
					show_update_hide_buttons();
					update_selected_keyword_count();
				});

				//
				// Define action for the update button within available keywords
				//
				function search_update() {

					var checked_keywords = $('.available-keyword[type="checkbox"]:checked');
					var checked_keyword_count = checked_keywords.length;
					
					if (checked_keyword_count === 0) {
						return false;
					}

					var search_keywords = [];
						checked_keywords.each(function(i) {
						search_keywords[i] = $(this).val();
					});

					unhighlight_predefined_keywords();

					search(search_keywords);

					ga('send', 'event', 'Search', 'Select Keywords', search_keywords.join());

					return false;
				}

				//
				// Define actions for update buttons
				//	
				$('#hyperoxia-article-search-update')
					.add('#hyperoxia-article-search-update2').click(function() {
					return search_update();
				});

				//
				// Dynamic content event for each keyword in search results
				//
				$(document).on('click', '.search-result-keyword', function() {
					$(this).remove();
					unhighlight_predefined_keywords();					

					var search_keywords = get_search_result_keywords();
					if (search_keywords.length === 0) {
						update_selected_keyword_count(); 
						clear_search();
					} else {
						search(search_keywords);
						update_selected_keyword_count(); 
					}
				});

				//
				// Dynaimc content event for clear all button
				//
				$(document).on('click', '.search-result-keyword-clear-all', function() {
					unhighlight_predefined_keywords();
					clear_search();		
				});


				///
                /// Show Modal
                ///

                $('#hcp-gate').modal('show');

                $('#hcp-gate').on('shown.bs.modal', function () {
                    ga('send', 'event', 'Modal', 'Show', 'HCP Gate');

                });
                $('#hcp-gate').on('hidden.bs.modal', function () {
                    ga('send', 'event', 'Modal', 'Click', 'Continue');
                });
			}
		},

        'oxygen_supplementation_therapy': {
            init: function () {
                var animateBlocks = $('.main section');
                var offset = 0.8;

                function hideBlocks(blocks, offset) {
                    blocks.each(function () {
                        if ($(this).offset().top > $(window).scrollTop() + $(window).height() * offset) {
                            $(this).addClass('is-hidden');
                        }
                    });
                }

                function showBlocks(blocks, offset) {
                    blocks.each(function () {
                        var winTop = $(window).scrollTop(),
                            winBottom = winTop + $(window).height() * offset,
                            elemTop = $(this).offset().top;

                        if( (elemTop <= winBottom) && (elemTop >= winTop) && $(this).hasClass('is-hidden') ){
                            $(this).removeClass('is-hidden');
                            var name = parseInt($(this).attr('id').substr($(this).attr('id').length -1))+1;
                            ga('send', 'event', 'Content Consumption', 'Scroll', 'Reached Oxygen Therapy Section '+ name);
                        }

                    });
                }

                $(window).on('scroll', function () {
                    if (!window.requestAnimationFrame) {
                        setTimeout(function () {
                            showBlocks(animateBlocks, offset);
                        }, 100);
                    } else {
                        window.requestAnimationFrame(function () {
                            showBlocks(animateBlocks, offset);
                        });
                    }
                });

                hideBlocks(animateBlocks, offset);

            }
        },

        'consequences_of_hyperoxia': {
            init: function () {
                $item = '.qa';
                $tracking = ['Damage','Lungs','ROS','Dysplasia'];
                $($item).on('click', 'a', function (e) {
                    e.preventDefault();
                    $this = $(this);
                    $track = $tracking[$this.attr('href').substr($this.attr('href').length -1)];
                    if ($this.hasClass('open')) {
                        $this.removeClass('open');
                        $this.next().hide("fast");
                    } else {
                        $this.addClass('open');
                        $this.next().show("fast", function () {
                            if(!$this.hasClass('tracked')){
                                $this.addClass('tracked');
                                //console.log('Consequences ' + $track);
                                ga('send', 'event', 'Window Shade', 'Expand', 'Consequences ' + $track);
                            }
                        });
                    }
                });
            }
        },

        'historical_perspectives': {
            init: function () {
                var timelineBlocks = $('.cd-timeline-block');
                var offset = 0.8;


                function hideBlocks(blocks, offset) {
                    blocks.each(function () {
                        if ($(this).offset().top > $(window).scrollTop() + $(window).height() * offset) {
                            $(this).find('.cd-timeline-img, .cd-timeline-content').addClass('is-hidden');
                        }
                    });
                }

                function showBlocks(blocks, offset) {
                    blocks.each(function () {
                        if ($(this).offset().top <= $(window).scrollTop() + $(window).height() * offset && $(this).find('.cd-timeline-img').hasClass('is-hidden')) {
                            $this = $(this);
                            $this.find('.cd-timeline-img, .cd-timeline-content').removeClass('is-hidden').addClass('bounce-in');
                            $date = $this.find('.cd-date').text();
                            //console.log($date);
                            ga('send', 'event', 'Content Consumption', 'Scroll', 'Timeline: ' + $date);
                        }
                    });
                }

                $(window).on('scroll', function () {
                    if (!window.requestAnimationFrame) {
                        setTimeout(function () {
                            showBlocks(timelineBlocks, offset);
                        }, 100);
                    } else {
                        window.requestAnimationFrame(function () {
                            showBlocks(timelineBlocks, offset);
                        });
                    }
                });

                hideBlocks(timelineBlocks, offset);

            }
        },

        'practice_and_protocols': {
            init: function () {
                //gform_wrapper
                var animateBlocks = $('.gform_wrapper');
                var offset = 0.95;

                function hideBlocks(blocks, offset) {
                    blocks.each(function () {
                        if ($(this).offset().top > $(window).scrollTop() + $(window).height() * offset) {
                            $(this).addClass('is-hidden');
                        }
                    });
                }

                function showBlocks(blocks, offset) {
                    blocks.each(function () {
                        var winTop = $(window).scrollTop(),
                            winBottom = winTop + $(window).height() * offset,
                            elemTop = $(this).offset().top;

                        if( (elemTop <= winBottom) && (elemTop >= winTop) && $(this).hasClass('is-hidden') ){
                            $(this).removeClass('is-hidden');
                            var name = $(this).prev().text();
                            ga('send', 'event', 'Content Consumption', 'Scroll', 'Reached Question '+ name);
                        }

                    });
                }

                $(window).on('scroll', function () {
                    if (!window.requestAnimationFrame) {
                        setTimeout(function () {
                            showBlocks(animateBlocks, offset);
                        }, 100);
                    } else {
                        window.requestAnimationFrame(function () {
                            showBlocks(animateBlocks, offset);
                        });
                    }
                });

                hideBlocks(animateBlocks, offset);


                $('.practice-and-protocols').on('click', '.more-info', function () {
                    $(this).css({'display': 'none'});

                    $info = $(this).siblings('.more');
                    if ($info.hasClass('show')) {
                        $(this).removeClass('open');
                        $info.removeClass('show');
                    } else {
                        $(this).addClass('open');
                        $info.addClass('show');
                    }
                });
            }
        },

        responsiveTable: function () {
            if ($('table').length) {
                // just get the user created tables
                $table = $('table').not('.crayon-table');

                //first fix any tables without theads
                $($table).each(function () {
                    $hasHead = $('thead td, thead th', this).length;
                    if (!$hasHead) {
                        $(this).prepend('<thead></thead>').find("tr:first").prependTo($('thead', this));
                    }
                });

                //second update tables to have data attrs
                $($table).each(function () {
                    $hasHead = $('thead td, thead th', this).length;
                    $col_titles = [];

                    if ($hasHead) {//make sure our current table has what we need to get started.
                        // cache our column titles (include td for bad html)
                        $(this).find('th, td').each(function () {
                            $content = $(this).text() + ': ';
                            $col_titles.push($content);
                        });

                        // add our column titles to data attrs on each tr>td
                        $(this).find('tr').each(function () {
                            $row = $(this);
                            $row.children("td").each(function (key) {
                                $(this).attr('data-label', $col_titles[key]);
                            });
                        });
                    }
                });
            }
        },
        breakpoint: {
            refreshValue: function () {
                this.value = window.getComputedStyle(
                    document.querySelector('body'), ':before'
                ).getPropertyValue('content').replace(/['"]+/g, '');
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
